<template>
  <v-layout class="mb-4 pb-4" wrap>
    <v-flex xs12>
      <AppTitle title="Paginas web/mobile" subtitle="" />
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-data-table
          item-key="id"
          :headers="headers"
          :items="items"
          :loading="loading"
          rows-per-page-text="Pedidos por pagina"
          no-data-text="No hay pedidos paginas ingresadas"
          class="elevation-0"
          hide-actions
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                {{ props.item.list }}
              </td>
              <td>
                <span v-html="props.item.name" />
              </td>
              <td>
                <span v-if="props.item.type === 1">Web</span>
                <span v-else-if="props.item.type === 2">CMS</span>
                <span v-else-if="props.item.type === 3">Categoria</span>
                <span v-else-if="props.item.type === 4">URL</span>
              </td>
              <td>
                <v-chip
                  v-if="props.item.status"
                  color="success"
                  text-color="white"
                  label
                  small
                  @click="changeProduct(props.item.id, props.item.status, props.item)"
                >
                  <v-icon left>check</v-icon> Activo
                </v-chip>
                <v-chip
                  v-else
                  color="error"
                  text-color="white"
                  label
                  small
                  @click="changeProduct(props.item.id, props.item.status, props.item)"
                >
                  <v-icon left>clear</v-icon> Inactivo
                </v-chip>
              </td>
              <td>
                <v-icon small class="mr-2" @click="editItem(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(props.item.id)">
                  delete
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-btn fab dark fixed bottom right color="success" @click="newItem">
        <v-icon>add</v-icon>
      </v-btn>
    </v-flex>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <AppTitleDialog
          :title="'Agregar pagina'"
          :close-action="
            () => {
              dialog = false
            }
          "
          close
        />
        <v-card-text class="pa-0">
          <v-container grid-list-lg>
            <v-layout wrap>
              <v-flex xs4>
                <v-text-field v-model="form.name" hide-details label="Nombre" />
              </v-flex>
              <v-flex xs4>
                <v-select v-model="form.type" hide-details :items="types" label="Tipo de pagina" />
              </v-flex>
              <v-flex xs4>
                <v-text-field v-model="form.list" hide-details type="number" label="Orden" />
              </v-flex>
              <v-flex xs4>
                <v-text-field v-model="form.namePublic" label="Nombre publico" />
              </v-flex>
              <v-switch v-model="form.status" label="Activa"></v-switch>
              <template v-if="form.type === 1">
                <v-flex xs6>
                  <v-text-field v-model="form.slug" label="Url amigable" />
                </v-flex>
              </template>
              <template v-if="form.type === 2">
                <v-flex xs4>
                  <v-select
                    v-model="form.section_id"
                    hide-details
                    item-text="name"
                    :items="sections"
                    item-value="id"
                    label="Paginas de contenido"
                  />
                </v-flex>
              </template>
              <template v-if="form.type === 3">
                <v-flex xs4>
                  <v-select
                    v-model="form.category_id"
                    hide-details
                    item-text="name"
                    :items="categories"
                    item-value="id"
                    label="Categoria"
                  />
                </v-flex>
              </template>
              <template v-if="form.type === 4">
                <v-flex xs4>
                  <v-text-field v-model="form.url" label="Url" placeholder="https://www.google.cl" />
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="dialog = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="accent" depressed @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { CONFIG } from '../../config'
import AppTitle from '../useful/title.vue'
import AppTitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'ViewPage',
  components: {
    AppTitle,
    AppTitleDialog
  },
  data() {
    return {
      loading: false,
      items: [],
      categories: [],
      tags: [],
      sections: [],
      dialog: false,
      form: {
        name: '',
        type: '',
        slug: '',
        list: '',
        status: true,
        category_id: 0,
        url: '',
        description: '',
        section_id: 0
      },
      types: [
        { value: 1, text: 'Web' },
        { value: 2, text: 'Paginas de contenido' },
        { value: 3, text: 'Categoria' },
        { value: 4, text: 'Url' }
      ],
      headers: [
        {
          text: 'Nº',
          align: 'left',
          sortable: false
        },
        {
          text: 'Pagina',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tipo',
          align: 'left',
          sortable: false
        },
        {
          text: 'Estado',
          align: 'left',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          sortable: false
        }
      ]
    }
  },
  watch: {
    'form.namePublic': function productName() {
      this.form.slug = this.toSeoUrl(this.form.namePublic)
    }
  },
  mounted() {
    this.getViews()
    this.getCategoriesAndTags()
    this.getSectionsPage()
  },
  methods: {
    async getViews() {
      try {
        const res = await this.$http.get(`${CONFIG}/views`, {
          p: 'getPageView'
        })
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getSectionsPage() {
      try {
        const res = await this.$http.get(`${CONFIG}/sections-page`, {
          p: 'getSectionsPage'
        })
        this.sections = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getCategoriesAndTags() {
      try {
        const res = await this.$http.get(`${CONFIG}/categories-tags`, {
          p: 'getCategoriesAndTags'
        })
        this.categories = res.data.categories
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async save() {
      try {
        await this.$http({
          method: this.form.id ? 'put' : 'post',
          url: this.form.id ? `${CONFIG}/views/${this.form.id}` : `${CONFIG}/views`,
          data: {
            p: 'savePageView',
            form: this.form
          }
        })
        this.dialog = false
        this.getViews()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteItem(id) {
      try {
        await this.$http.delete(`${CONFIG}/views/${id}`)
        this.getViews()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    newItem() {
      this.dialog = !this.dialog
      this.form = {
        name: '',
        type: '',
        slug: '',
        list: '',
        url: '',
        description: '',
        status: true,
        category_id: 0,
        section_id: 0
      }
    },
    editItem(item) {
      this.form = item
      this.dialog = !this.dialog
    },
    toSeoUrl(url) {
      return (
        url
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '-')
          .toLowerCase()
          .replace(/&/g, '-and-')
          // eslint-disable-next-line no-useless-escape
          .replace(/[^a-z0-9\-]/g, '')
          .replace(/-+/g, '-')
          .replace(/^-*/, '')
          .replace(/-*$/, '')
      )
    }
  }
}
</script>
